import React, { useContext, useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useAirtableSessions } from "../hooks/useAirtable";
import { GlobalStateContext, GlobalDispatchContext } from "../context/GlobalContextProvider";
import CompanyListItem from "./CompanyListItem"

const CompanyList = ({ filterByOwnerEmail }) => {
  const [fetchSessions, setFetchSessions] = useState(false)
  const companySessionMap = useAirtableSessions(null, fetchSessions)
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const companies = useStaticQuery(graphql`
    query CompanyListQuery {
      allAirtable(filter: {table: {eq: "Companies"}, data: {EC_Membership_Type__or_Blockspaces_the_company_: {eq: 1}}}, sort: {fields: data___api_company_name, order: ASC}) {
        edges {
          node {
            data {
              api_company_name
              Logo {
                localFiles {
                  childImageSharp {
                    fixed(width:50) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                  extension
                  url
                }
              }
              Active_Members
              Adjusted_Support_Hours
              AM_Name
              AM_Email
              #_Coaching_Hours
              Description
              Id
              Staff
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if(Object.keys(state.companySessionMap).length > 0) { // this check might cause problems
      setFetchSessions(false)
    } else {
      setFetchSessions(true)
    }
  }, [])

  useEffect(() => {
    dispatch({type:'UPDATE_COMPANY_SESSION_MAP', payload: companySessionMap})
  }, [companySessionMap])

  return (
    <div style={{maxWidth: 1000, width: '100%'}}>
      {
        companies &&
        companies.allAirtable.edges.filter(e => {
          if(filterByOwnerEmail) {
            return e.node.data.AM_Email && e.node.data.AM_Email[0] === filterByOwnerEmail
          } else {
            return true
          }
        }).map((edge) => {
          return(
            <CompanyListItem key={edge.node.data.Id} company={edge.node.data} sessions={state.companySessionMap && state.companySessionMap[edge.node.data.Id]}/>
          )
        })
      }
    </div>
  )
}

export default CompanyList
